<template>
  <div>
    <el-dialog
      :visible.sync="show"
      :show-close="false"
      width="1200px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="state">
        <img src="../assets/images/serve/gth.png" alt="" />
        您的资料还没完善，可前往吧【个人中心】-【基础设置】进行完善
      </div>
      <div class="content">
        完善资料后您可以：
        <ul>
          <li>发布需求</li>
          <li>入驻开店</li>
          <li>购买服务</li>
          <li>发帖</li>
          <li>接单</li>
          <li>......</li>
        </ul>
      </div>
      <div class="foot-btn">
        <el-button class="f-btn" @click="goSet">去完善</el-button>
        <el-button class="btn" @click="handleClose">再逛逛</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    handleClose() {
      this.$emit("close");
    },
    goSet() {
      sessionStorage.setItem("current", "4");
      this.handleClose();
      this.$router.push({ path: "/setInfo" });
    },
  },
};
</script>

<style lang="scss" scoped>
.state {
  padding: 0px 0 10px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #333333;
  span {
    color: #e40012;
  }
  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}
.publicity {
  padding: 57px 0 0;
  display: flex;
  align-items: center;
}
.foot-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  font-size: 16px;
  color: #e40012;
  .f-btn {
    // padding: 15px 180px;
    width: 247px;
    height: 60px;
    background: #e40012;
    color: #fff;
    font-size: 18px;
    margin-right: 120px;
  }
  .btn {
    width: 247px;
    height: 60px;
    background: #fff;
    color: #e40012;
    font-size: 18px;
    border: 1px solid #e40012;
  }
}
.content {
  padding: 30px;
  color: #222;
  font-size: 18px;
  ul {
    padding: 20px;
  }
  li {
    color: #666;
    margin-top: 20px;
  }
}
</style>
