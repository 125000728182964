<template>
    <div>
        <div class="search">
            <div class="enter">
                <div class="category">
                    <el-dropdown trigger="click" placement="bottom" @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{ typeText }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in domwList" :key="item.id">{{
                item.name
              }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <input type="text" placeholder="请输入关键字搜索" v-model="form.key" class="input" />
                <button class="enter-btn" @click="search">搜索</button>
            </div>
            <button class="release" @click="release">发布需求</button>
        </div>
        <Release v-if="dialogVisible" :dialogVisible="dialogVisible" @close="close" />
        <Complete @close="close" :show="show" />
    </div>
</template>

<script>
import Release from "./serve/release.vue";
import Complete from "./complete.vue";
export default {
    components: { Release, Complete },
    props: {
        classifyBaseList: Array,
        cur: Number
    },
    data() {
        return {
            form: {
                key: "",
                classifyId: 0,
            },
            typeText: "类别",
            domwList: [],
            dialogVisible: false,
            token: false,
            show: false,
        };
    },
    mounted() {
        this.getClass();
        if (sessionStorage.getItem("token")) {
            this.token = true;
        }
    },
    methods: {
        getClass() {
            this.$util.post("/shop/allClassify").then((res) => {
                console.log(res);
                this.domwList = res.data;
            });
        },
        search() {

            if (this.cur != undefined || this.cur != '') {
                this.form.classifyId = this.cur
            }

            if (this.form.key == "") {
                this.$message.error("请输入搜索内容");
            } else {
                this.$emit("search", this.form);
                this.$router.push({ path: "/search", query: { keyword: this.form } });
            }

        },
        close() {
            this.dialogVisible = false;
            this.show = false;
        },
        handleCommand(item) {
            console.log(item);
            this.typeText = item.name;
            this.form.classifyId = item.id;
        },
        release() {
            if (sessionStorage.getItem("token") && sessionStorage.getItem("payPsd") == "true") {
                this.dialogVisible = true;
            } else if (!sessionStorage.getItem("token")) {
                this.$message.error("请先进行登录");
            } else if (sessionStorage.getItem("payPsd") == "false") {
                this.show = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 0 auto 30px;
    // background-color: #fff;
    .enter {
        width: 820px;
        height: 50px;
        display: flex;
        align-items: center;
        border: 1px solid #e40012;
        .category {
            width: 100px;
            // height: 100%;
            text-align: center;
            border-right: 1px solid #c4c4c4;
        }
        .input {
            flex: 1;
            padding: 0 30px;
            height: 36px;
            border: none;
            outline: none;
        }
        .enter-btn {
            width: 150px;
            height: 100%;
            background: #e40012;
            border: none;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
        }
    }
    .release {
        margin-left: 30px;
        width: 150px;
        height: 50px;
        border: 1px solid #f1f1f1;
        color: #e40012;
        cursor: pointer;
        background: #fff;
        font-size: 16px;
    }
}
</style>
