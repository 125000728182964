<template>
	<div>
		<Search />
		<div class="video-bg" :style="`background-image: url(${$util.host+bgImg});`">
			<video controls :src="$util.host + video"></video>
		</div>
		<main>
			<div class="notice">
				<div class="real-time">实时通知</div>
				<div class="win-b">
					<div class="win-info">
						<div class="win-num">{{ info.NewestNum1 }}</div>
						<div class="info-title">中标信息</div>
						<div class="info">
							<div :class="{ marquee_top: animate }">
								<div class="info-content" v-for="(item, index) in listText1" :key="item.id">
									{{ item.createTime }} {{ item.name }} {{ item.msg }}
								</div>
							</div>
						</div>
					</div>
					<div class="win-img">
						<img :src="$util.host + info.BannerImg1.paramMsg" alt="" />
					</div>
				</div>
				<div class="win-b">
					<div class="win-img">
						<img :src="$util.host + info.BannerImg2.paramMsg" alt="" />
					</div>
					<div class="win-info win-left">
						<div class="win-num">{{ info.NewestNum2 }}</div>
						<div class="info">
							<div class="info-title">需求发布</div>
							<div :class="{ marquee_top: animate }">
								<div class="info-content" v-for="item in listText2" :key="item.id">
									{{ item.createTime }} {{ item.name }} {{ item.msg }}
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="win-b">
					<div class="win-info">
						<div class="info-title">接单成交</div>
						<div class="win-num">{{ info.NewestNum3 }}</div>

						<div class="info">
							<div :class="{ marquee_top: animate }">
								<div class="info-content" v-for="item in listText3" :key="item.id">
									{{ item.createTime }} {{ item.name }} {{ item.msg }}
								</div>
							</div>
						</div>
					</div>
					<div class="win-img">
						<img :src="$util.host + info.BannerImg3.paramMsg" alt="" />
					</div>
				</div>
			</div>
		</main>
		<div class="introduce">
			<div class="real-time">平台介绍</div>
			<div class="goald">
				<div class="goald-item pink" @click="goContent(info.HomeHtml1.paramKey)">
					<img :src="$util.host + info.HomeHtml1.paramMsg" alt="" />
				</div>
				<div class="goald-item blue" @click="goContent(info.HomeHtml2.paramKey)">
					<img :src="$util.host + info.HomeHtml2.paramMsg" alt="" />
				</div>
				<div class="goald-item lime" @click="goContent(info.HomeHtml3.paramKey)">
					<img :src="$util.host + info.HomeHtml3.paramMsg" alt="" />
				</div>
				<div class="goald-item pink" @click="goContent(info.HomeHtml4.paramKey)">
					<img :src="$util.host + info.HomeHtml4.paramMsg" alt="" />
				</div>
				<div class="goald-item blue" @click="goContent(info.HomeHtml5.paramKey)">
					<img :src="$util.host + info.HomeHtml5.paramMsg" alt="" />
				</div>
				<div class="goald-item pink" @click="goContent(info.HomeHtml6.paramKey)">
					<img :src="$util.host + info.HomeHtml6.paramMsg" alt="" />
				</div>
			</div>
		</div>
		<!-- 客服 -->
		<!-- <div class="chat" @click="">
            <div class="top">
                <span>在线咨询</span>
                <img src="../../assets/images/lg.png" alt="">
            </div>
            <div class="bottom">ONLINE CONSULTING</div>
        </div> -->
		<!-- <div class="dialogue">
            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="在线咨询" name="1">
                    <div style="width:200px;height:300px"></div>
                    <div></div>
                </el-collapse-item>
            </el-collapse>
        </div> -->
		<!-- 尾部 -->
		<Footer />
		<!-- 完善资料 -->
		<Complete :show="show" @close="close" />
	</div>
</template>
<script>
	import Footer from "@/components/footer.vue";
	import Search from "@/components/search.vue";
	import Complete from "../../components/complete.vue";
	export default {
		components: {
			Footer,
			Search,
			Complete
		},
		metaInfo: {
			title: '企业服务_在线企服行业交易综合服务平台_企胖胖', // set a title
			meta: [{
					name: 'description',
					content: '找企业服务，注册公司，代理记账，商标转让，资质认证，设计，运营推广，商务服务，检验检测到企胖胖 面向中小微企业和个人创业者的企业服务平台，您身边的企业基建服务专家.'
				},
				{
					name: 'keyWords',
					content: '企胖胖，企业服务，注册公司，企业代记账，商标转让，高新技术认证，设计动漫，运营推广，商务服务，检验检测，网站建设'
				},
			]
		},
		data() {
			return {
				info: {}, //首页信息
				show: false, //完善资料弹出层
				myInfo: {}, //我的信息
				video: "", //视频
				listText1: [],
				listText2: [],
				listText3: [],
				animate: false,
				activeNames: "",
				bgImg: ''
			};
		},
		mounted() {
			this.allData();
			this.getInfo();
			this.homeVideo();
			this.login_logon_bg()
		},
		methods: {
			login_logon_bg() {
				let data = {
					paramKey: "video_bg"
				}
				this.$util.post('/sys-param/list', data).then(res => {
					this.bgImg = res.data[0].paramValue
				})
			},
			handleChange() {},
			// 首页视频
			homeVideo() {
				this.$util
					.post("/sys-param/list", {
						paramKey: "HomeVideo",
					})
					.then((res) => {
						console.log(res);
						this.video = res.data[0].paramValue;
					});
			},
			// 获取首页所有数据
			allData() {
				this.$util.post("/home/index").then((res) => {
					console.log(res);
					this.info = res.data;
					this.listText1 = res.data.Newest1;
					this.listText2 = res.data.Newest2;
					this.listText3 = res.data.Newest3;
					this.setList();
				});
			},
			//设置走马灯效果
			setList() {
				var _this = this;
				clearInterval(setTimeFun);
				var setTimeFun;
				setTimeFun = setInterval(function() {
					_this.animate = true;
					setTimeout(() => {
						_this.animate = false;
						_this.listText1.push(_this.listText1[0]);
						_this.listText1.shift();
						_this.listText2.push(_this.listText2[0]);
						_this.listText2.shift();
						_this.listText3.push(_this.listText3[0]);
						_this.listText3.shift();
					}, 750);
				}, 2000);
			},
			getInfo() {
				this.$util.post("/user/info").then((res) => {
					this.myInfo = res.data;
					// 如果没有设置支付密码  完善信息弹出层展示
					if (!this.myInfo.hasPayPsd) {
						this.show = true;
					}
				});
			},
			// 底部六宫格详情跳转
			goContent(key) {
				this.$router.push({
					path: "/content",
					query: {
						key
					}
				});
			},
			close() {
				this.show = false;
			},
		},
	};
</script>
<style>
	.marquee_list {
		display: block;
	}

	.marquee_top {
		transition: all 0.75s;
		margin-top: -48px;
	}
</style>
<style lang="scss" scoped>
	a {
		color: #222;
	}

	.video-bg {
		// height: 500px;
		// background: url("../../assets/images/home/video-bg.png") round;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;

		video {
			width: 1;
			max-width: 43vw;
			max-height: 49vh;
			// max-width: 1200px;
			// max-height: 480px;
			// object-fit: fill;
		}
	}

	main {
		background: #f8f8f8;
		padding-bottom: 50px;
	}

	.notice {
		width: 1200px;
		margin: 0 auto;
	}

	.real-time {
		padding: 15px 0 10px;
		border-bottom: 1px solid #dbdbdb;
		font-size: 15px;
		color: #000;
		font-weight: bold;
		line-height: 20px;
	}

	.win-b {
		display: flex;
		margin-top: 50px;
		height: 410px;
		overflow: hidden;

		.win-left {
			margin: 0 0 0 20px !important;
		}

		.win-info {
			width: 345px;
			margin-right: 20px;
			background: #fff;
			box-shadow: 1px 1px 10px #ccc;
			position: relative;

			.win-num {
				height: 140px;
				background: linear-gradient(#f9c4c8, #fef9fa, #ffffff);
				color: #e40012;
				font-size: 30px;
				text-align: center;
				font-weight: bold;
				line-height: 140px;
			}

			.info-title {
				text-align: center;
				font-size: 20px;
				line-height: 45px;
				height: 45px;
				top: 120px;
				color: #000;
				left: 0;
				display: block;
				font-weight: bold;
				background: #ffffff;
				position: absolute;
				width: 100%;
				border-bottom: 1px solid #f1f1f1;
			}

			.info {
				padding: 0 30px 30px;

				.info-content {
					z-index: -1 !important;
					padding-top: 30px;
					font-size: 14px;
					color: #666666;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.win-img {
			width: 843px;
			height: 410px;
			box-shadow: 1px 1px 10px #ccc;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.introduce {
		width: 1200px;
		margin: 0 auto 60px;
	}

	.goald {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.goald-item {
			width: 386px;
			height: 386px;
			margin-top: 20px;
			text-align: center;
			color: #000;
			cursor: pointer;
			transition: all 0.5s linear;
			font-size: 22px;

			img {
				// display: block;
				// margin: 130px auto 75px;
				width: 100%;
				height: 100%;
			}
		}

		.goald-item:hover {
			box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);

			transform: translate(0, -5px);

			transition: all 0.5s;
		}

		.pink {
			background: #fef5f5;
		}

		.blue {
			background: #f4faff;
		}

		.lime {
			background: #f4faf1;
		}
	}

	.chat {
		padding: 10px;
		width: 143px;
		height: 49px;
		position: fixed;
		right: 0;
		background: #e40012;
		z-index: 10000;
		top: 40%;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #ffffff;
		border-radius: 4px 0px 0px 6px;

		.top {
			display: flex;
			align-items: center;

			img {
				margin-left: 20px;
				// width: 100%;
				// height: 100%;
				cursor: pointer;
			}
		}

		.bottom {
			font-size: 12px;
			color: #ffffff;
			opacity: 0.5;
		}
	}

	.dialogue {
		width: 400px;
		position: fixed;
		right: 40px;
		bottom: 20px;
		z-index: 10000;

		/deep/.el-collapse-item__header {
			// .el-collapse-item__header {
			background-color: #e40012;
			border-bottom: 1px solid #e40012;
			// }
		}
	}
</style>
